import api from '../../utils/api'

// 授权
export async function card_info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '李明',
  //         id_no: '310*******0814',
  //         medical_card_no: '3239393993',
  //         phone: '186215***08'
  //       }
  //     })
  //   }, 1000)
  // })
  return api.get(`/api/v1/mp/medical_cards`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/medical_cards`,payload)
}

export async function card_no (payload) {
  console.log(payload)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          count: Math.random() > 0.5 ? 1 : -1
        }
      })
    }, 1000)
  })
  //return api.get(`/api/v1/mp/medical_cards/count`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/medical_cards/count`,payload)
}

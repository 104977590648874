<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />

      <div class="head">
        <div class="qr_div" ref="qr_div">
        </div>
      </div>

      <van-skeleton title :row="4" :loading="loading">
        <div class="main">
          <div class="list">
            <div class="left">姓名</div>
            <div class="right">{{data.name}}</div>
          </div>
          <div class="list">
            <div class="left">身份证号</div>
            <div class="right">{{data.id_no}}</div>
          </div>
          <div class="list">
            <div class="left">健康卡号</div>
            <div class="right">{{format_medical_card(data.medical_card_no)}}</div>
          </div>
          <div class="list">
            <div class="left">手机号</div>
            <div class="right">{{data.phone}}</div>
          </div>
        </div>
      </van-skeleton>

    </div>
  </div>
</template>

<script>
import { card_info, card_no } from './servive'
import QRCode from 'qrcodejs2'
import {format_medical_card} from '../../utils/format'
export default {
  data() {
    return {
      data: {},
      hasCard: 'loading',
      loading: false
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.get_card()
  },
  methods: {
    format_medical_card,
    qrcode() {
      new QRCode(this.$refs.qr_div, {
        width: 200,
        height: 200,
        text: this.data.medical_card_no,
        padding: 6
      })
    },
    async get_card() {
      this.loading = true
      try {
        const {data} = await card_info()
        this.data = data
        this.qrcode()
      } finally {
        this.loading = false
      }
    },

    async get_card_no() {
      try {
        const {data} = await card_no()
        this.hasCard = data.count > 0
        if (this.hasCard) {
          this.get_card()
        }
      } finally {
        //this.loading = false
      }
    },
  },
};
</script>

<style scoped>
.g_page_box {
  background: #f7f7f7;
}

.head {
  background: #ffffff;
  border: 1px solid #eaeaea;
  padding: 26px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr_div {
  width: 200px;
  height: 200px;
}

.main {
  padding-top: 10px;
  border-bottom: 1px solid #eaeaea;
}

.list {
  background: #ffffff;
  height: 44px;
  background: #ffffff;
  border-top: 1px solid #eaeaea;
  padding: 0 23px;
  line-height: 44px;
  font-size: 14px;
  font-weight: 400;
  color: #242938;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
